import { type ReactNode } from 'react';
import { Button, Dialog, Heading, ModalOverlay, Modal as RAModal, Separator } from 'react-aria-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { twMerge } from 'tailwind-merge';

type Props = {
  isOpen: boolean;
  title: string | ReactNode;
  description?: string;
  children?: ReactNode;
  className?: string;
  hideCloseButton?: boolean;
  onCancel?: () => void;
};

export default function Modal({
  isOpen,
  title,
  description,
  children,
  className,
  hideCloseButton = false,
  onCancel,
}: Props) {
  return (
    <ModalOverlay
      isDismissable
      isOpen={isOpen}
      aria-label="modal"
      className={({ isEntering, isExiting }) =>
        twMerge(
          'fixed inset-0 z-10 flex min-h-full items-center justify-center overflow-y-auto bg-[#00000080] p-4 text-center',
          isEntering ? 'duration-300 ease-out animate-in fade-in' : '',
          isExiting ? 'duration-200 ease-in animate-out fade-out' : '',
        )
      }
    >
      <RAModal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className={({ isEntering, isExiting }) =>
          twMerge(
            'w-full max-w-md overflow-hidden rounded-[4px] bg-white text-left align-middle shadow-xl',
            isEntering ? 'duration-300 ease-out animate-in zoom-in-95' : '',
            isExiting ? 'duration-200 ease-in animate-out zoom-out-95' : '',
            className,
          )
        }
      >
        <Dialog role="alertdialog" className="relative m-0 outline-none">
          {({ close }) => (
            <div className="flex h-full flex-col">
              <div className="flex items-center justify-between p-6">
                <Heading slot="title" className="my-0 text-[16px] font-[500] leading-6 tracking-[-0.25px] text-black">
                  {title}
                </Heading>
                {!hideCloseButton && (
                  <Button
                    className="p-2 text-slate-500 hover:text-slate-600"
                    onPress={() => {
                      onCancel?.();
                      close();
                    }}
                  >
                    <FontAwesomeIcon icon={faXmark} className="h-4 w-4 cursor-pointer text-[#00000066]" />
                  </Button>
                )}
              </div>
              <Separator className="m-0" />
              <div className="flex-1 overflow-y-auto p-6">
                {description && <p className="m-0 text-slate-500">{description}</p>}
                {children && <div className="text-slate-500">{children}</div>}
              </div>
            </div>
          )}
        </Dialog>
      </RAModal>
    </ModalOverlay>
  );
}
